<template>
  <div>
    <div class="login usr">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <div v-show="$store.state.common.sIsLoading">Loading...</div>
      <div v-show="!$store.state.common.sIsLoading">
        <h2>アンケート</h2>
        <p>
          <button
            :disabled="$store.state.common.sServerProcessing"
            type="button"
            class="uk-button uk-button-secondary uk-width-1-1"
            @click="doMoveToAnswerPage(1)"
          >
            準備中
          </button>
          <button
            :disabled="$store.state.common.sServerProcessing"
            type="button"
            class="uk-button uk-button-secondary uk-width-1-1"
            @click="doMoveToAnswerPage(3)"
          >
            実施中
          </button>
          <button
            :disabled="$store.state.common.sServerProcessing"
            type="button"
            class="uk-button uk-button-secondary uk-width-1-1"
            @click="doMoveToAnswerPage(7)"
          >
            診断結果
          </button>
        </p>
        <h2>ストレスチェックとは</h2>
        <p>
          ストレスチェックとは労働安全衛生法第66条の10に基づき、2015年12月から特定の事業場で実施を義務付けられているストレスに関する検査のことです。<br />
          50人以上の労働者を抱える事業場では、すべての労働者に対して年1回の実施が義務付けられています。<br />
        </p>
        <p>
          ストレスチェックでは、まず労働者が「自分のストレスがどのような状態にあるのか」について質問票で選択回答し、企業が質問票を集計・分析し、本人に結果を通知します。<br />
          ストレスチェックは、労働者のメンタルヘルス不調を未然に防止し、職場環境の改善といった目的のもと実施されます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import constant from "@/constant.js";

export default {
  data() {
    return {
      vComId: this.$store.state.common.sComId,
      vEmpCode: this.$store.state.common.sUserCode,
      vTblListData: [],
      vMessage: "",
    };
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // ログイン状態判定
    this.doAuthLoginInfo();
    if (!this.$store.state.common.sIsLogined) {
      this.$router.push("/login");
    }
    // 画面タイトル
    this.$store.commit("common/doSetScreenTitle", {
      vScreenTitle: "回答者/ホーム",
    });
    // DB参照処理
    if (this.vComId) {
      this.vMessage = "";
      this.$axios
        .get(constant.cServerRoute.respondent.home, {
          params: {
            pComId: this.vComId,
            pEmpCode: this.vEmpCode,
          },
        })
        .then(
          function (response) {
            console.log(response);
            // 実施管理データ参照処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // 実施管理データを保持する
              if (response.data.return_data) {
                this.vTblListData = response.data.return_data;
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
          this.vMessage = error;
        });
    }
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック";
  },
  methods: {
    // 回答ページへ遷移する
    doMoveToAnswerPage(scStatus) {
      // 実施IDを遷移先の回答ページへ渡したい
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      this.$store.state.answer.sScStatusForScreen = scStatus;
      if (this.$store.state.answer.sScStatusForScreen == 7) {
        this.$router.push("/respondent-results");
      } else {
        this.$router.push("/respondent-answer");
      }
      // ボタン制御
      // this.$store.commit('common/doEndServerProcess');
    },
    // ID,パスワード照合
    doAuthLoginInfo() {
      if (sessionStorage.getItem("storageId")) {
        let createHash = require("sha256-uint8array").createHash;
        let tmp = createHash()
          .update(sessionStorage.getItem("storagePw"))
          .digest("hex");
        this.$axios
          .post(constant.cServerRoute.common.login, {
            alias_id: sessionStorage.getItem("storageAliasId"),
            pLoginId: sessionStorage.getItem("storageId"),
            pLoginPw: tmp,
          })
          .then(
            function (response) {
              console.log(response);
              if (
                response.data.return_result !=
                constant.cServerReturnResult.success
              ) {
                this.$store.state.common.sIsLogined = false;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          });
      }
    },
  },
};
</script>
